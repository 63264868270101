







import { Component, Vue } from "vue-property-decorator";

@Component
export default class CookiesView extends Vue {
  mounted(): void {
    const cookieDec = document.getElementById("cookie-dec");
    const cookieDecScript = document.createElement("script");
    cookieDecScript.src =
      "https://consent.cookiebot.com/fe6bc103-cb6f-4073-a586-81ac0733b341/cd.js";
    cookieDec!.appendChild(cookieDecScript);
  }
}
